import React from 'react';
import PasswordError from '../components/common/errors/PasswordError.component';

const MIN_LENGTH_NUMBER = 2;
const MIN_LENGTH_DNI = 7;
const MIN_LENGTH_PHONE_NUMBER = 10;
const MAX_LENGTH_PHONE_NUMBER = 20;
const MAX_LENGTH_EMAIL = 60;
const EMAIL_ERROR_MESSAGE = 'El e-mail ingresado no es válido';
const EMAIL_REQUIRED_MESSAGE = 'El e-mail es requerido';
const PASSWORD_REQUIRED_MESSAGE = 'La contraseña es requerida';
const PASSWORD_ERROR_MESSAGE =  'La contraseña no es suficientemente segura';
const REPPASS_ERROR_MESSAGE = 'Sus contraseñas no coinciden';
const REPPASS_EMPTY_MESSAGE = 'Por favor, repetí tu contraseña';
const CUILCUIT_ERROR_MESSAGE = 'El Cuit o Cuil no es válido';
const minLengthMessage = (MIN_LENGTH_NUMBER) => `Ingresá al menos ${MIN_LENGTH_NUMBER} caracteres.`;
const minLengtNumberhMessage = (MIN_LENGTH_NUMBER) => `Tiene que tener ${MIN_LENGTH_NUMBER} caracteres.`;
const MAX_LENGTH_MESSAGE = 'El texto es demasiado largo';
const TEXT_ERROR_MESSAGE = 'Debe ser un texto válido';


export const emailRegex = RegExp(/^([a-zA-Z\-0-9%\._+]{6,30})@(([a-zA-Z\-0-9]+)(\.+[a-zA-Z]{2,})+)$/);

export const passwordRegex = RegExp(/((?=.*\d)(?=.*[A-Z])(?=.*\W{0,}).{6,20})/);

export const longerPasswordRegex = RegExp(/((?=.*\d)(?=.*[A-Z])(?=\w*[a-z])(?=.*\W{0,}).{8,20})/);

export const onlyTextRegex = RegExp(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/);

export const textNumbersAndSpecialCharactersRegex = RegExp(/([^A-Za-zÀ-ÖØ-öø-ÿ0-9\!\s'´\-,;.":¿?¡!()])/);

export const phoneRegex = RegExp(/^[0-9]{7,20}$/);

export const anyCharacterRegex = RegExp(/^([A-Za-zÀ-ÖØ-öø-ÿ0-9\!]{1,}\s{1})*([A-zÀ-ú0-9\!]{1,}){1}$/);

export const specialCharacterLimitRegex = RegExp(/([-',._!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]){4,}/);

export const DEFAULT_VALIDATION =  {
  valid: true,
  error: ''
};

export const validateEmail = (value, message = EMAIL_ERROR_MESSAGE, emptyMessage = EMAIL_REQUIRED_MESSAGE) => {
  if(!value) {
    return { valid: false, error: emptyMessage };
  }
  const valid = emailRegex.test(value) && value.length <= MAX_LENGTH_EMAIL;
  const error = valid ? '' : message;
  return { valid, error };
};

export const validatePassword = (value, message, emptyMessage = PASSWORD_REQUIRED_MESSAGE, regex = passwordRegex) => {
  if (!value) {
    return { valid: false, error: emptyMessage }
  }
  const valid = regex.test(value)
  const error = valid ? '' : message || <PasswordError message={PASSWORD_ERROR_MESSAGE} />

  return { valid, error }
}

export const validateRepeatPassword = (pass = '', repPass = '', message = REPPASS_ERROR_MESSAGE, emptyMessage = REPPASS_EMPTY_MESSAGE) => {
  if (!pass.length) {
    return { valid: false, error: emptyMessage }
  }
  const valid = pass === repPass;
  const error = valid ? '' : message;
  return { valid, error };
};

export const validateCuilCuit = (value) => {
  let cuilCuit = value.replace(/-/g, '');
  if(cuilCuit.length !== 11) {
    return { valid: false, error: CUILCUIT_ERROR_MESSAGE };
  }

  let acumulado	= 0;
  let digits	= cuilCuit.split('');
  let digit = digits.pop();

  for(let i = 0; i < digits.length; i++) {
    acumulado += digits[9 - i] * (2 + (i % 6));
  }

  let verif = 11 - (acumulado % 11);
  if(verif === 11) {
    verif = 0;
  }

  const valid = +digit === verif;
  const error = valid ? '' : CUILCUIT_ERROR_MESSAGE;

  return { valid, error };
};

export const formatCuit = (value) => {
  let formatted = value.replace(/\D/g, ''); // eliminar todos los caracteres que no sean dígitos
  formatted = formatted.slice(0, 11); // limitar la longitud del CUIT/CUIL a 11 caracteres
  formatted = formatted.replace(/^(\d{2})(\d{0,8})?(\d{0,1})?$/, (match, p1, p2, p3) => {
    let parts = [];
    if (p1) parts.push(p1);
    if (p2) parts.push(p2);
    if (p3) parts.push(p3);
    return parts.join('-');
  }); // aplicar el formato correcto
  return formatted;
};

export const roundBase1000 = (number) => {
  if (!number) return 0;
  // calcular el 1% de paga mínimo
  const minPayment = number * 0.01;
  // redondear hacia arriba al múltiplo de 1000 más cercano
  return Math.ceil(minPayment / 1000) * 1000;
};

export const validateIsRequired = (value, message) => {
  const valid = !!value;
  const error = valid ? '' : message;

  return { valid, error };
};

export const validateArrayNotEmpty = (value, message) => {
  const valid = value && value.length > 0;
  const error = valid ? '' : message;

  return { valid, error };
};

export const validateInputText = (
  value,
  message,
  allowsSpecialCharacters = false,
  minLength = MIN_LENGTH_NUMBER,
  maxLength = 200) => {
  if(value === '') {
    return { valid: false, error: message };
  }
  if(value.length < minLength) {
    return { valid: false, error: minLengthMessage(minLength) };
  }
  if(value.length > maxLength) {
    return { valid: false, error: MAX_LENGTH_MESSAGE };
  }
  let inputTextRegex = allowsSpecialCharacters ? anyCharacterRegex : onlyTextRegex

  if(!inputTextRegex.test(value)) {
    return { valid: false, error: TEXT_ERROR_MESSAGE };
  }
  return { valid: true, error: '' };

};

export const validateTextArea = (value, message, maxLength = 250, limitSpecialCharacters = false) => {
  if(value === '') {
    return { valid: false, error: message };
  }

  if(value.length > maxLength) {
    return { valid: false, error: MAX_LENGTH_MESSAGE };
  }

  if(limitSpecialCharacters && specialCharacterLimitRegex.test(value)) {
    return { valid: false, error: TEXT_ERROR_MESSAGE };
  }
  if(textNumbersAndSpecialCharactersRegex.test(value)) {
    return { valid: false, error: TEXT_ERROR_MESSAGE };
  }
  return { valid: true, error: '' };

};

export const validateDNI = (value, message) => {
  if(value === '') {
    return { valid: false, error: message };
  }
  if(value.length < MIN_LENGTH_DNI){
    return { valid: false, error: minLengthMessage(MIN_LENGTH_DNI) };
  }
  return { valid: true, error: '' };
};

export const validatePhoneNumber = (value, message) => {
  if(value === '') {
    return { valid: false, error: message };
  }
  if(value.length < MIN_LENGTH_PHONE_NUMBER){
    return { valid: false, error: minLengtNumberhMessage(MIN_LENGTH_PHONE_NUMBER) };
  }
  if(value.length > MAX_LENGTH_PHONE_NUMBER){
    return { valid: false, error: MAX_LENGTH_MESSAGE };
  }
  return { valid: true, error: '' };
};

// Receives a callback to render the message
// EJ. (minLength) => `You need at least ${minLength} characters.`,
export const validateMinLength = (
  value = '',
  minLength = MIN_LENGTH_PHONE_NUMBER,
  messageConstructorCallback = (value) => minLengtNumberhMessage(value),
  emptyMessage
) => {
  if (emptyMessage && !value.length) {
    return { valid: false, error: emptyMessage };
  }
  if(value.length < minLength){
    return { valid: false, error: messageConstructorCallback(minLength) };
  }
  return { valid: true, error: '' };
};

export const validateLength = (
  value, 
  lengthNumber = MIN_LENGTH_PHONE_NUMBER, 
  messageConstructorCallback = (value) => minLengtNumberhMessage(value)
  ) => {
  if(value.length !== lengthNumber){
    return { valid: false, error: messageConstructorCallback(lengthNumber) };
  }
  return { valid: true, error: '' };
};

export const validateMinMaxNumber = ( currentValue, minValue, maxValue, minValueMessage, maxValueMessage ) => {

  currentValue = !currentValue ? 0 : currentValue;

  if (currentValue < minValue) {
    return { valid: false, error: minValueMessage };
  }

  if (currentValue > maxValue) {
    return { valid: false, error: maxValueMessage };
  }

  return { valid: true, error: '' };
}
