import React, { useState } from 'react';
import { Box, Grid, makeStyles, Checkbox, FormHelperText } from '@material-ui/core';
import TextField from '../../common/TextField.component';
import MaskedTextField from '../../common/MaskedTextField.component';
import clsx from 'clsx';
import Button from '../../common/button.component';
import DropZoneSection from './DropZoneSection.component';
import PropTypes from 'prop-types';
import Text from '../../common/text.component';
import Link from '../../common/Link.component';

const useStyle = makeStyles(
  (theme) => ({
    registerForm: {
      margin: `${theme.spacing(3)}px 0`,
      [theme.breakpoints.down('sm')] : {
        margin: '0px',
      }
    },
    textInput: {
      width:'100%',
      marginTop: '2rem',
      '& input': {
        padding: '.9688rem 1.5rem',
      },
      '& label': {
        paddingLeft: '1rem',
        color: `${theme.palette.grayScale.g400} !important`,
        transform: 'translate(.438rem, 1rem) scale(1)',
      },
      '& fieldset': {
        borderColor: theme.palette.grayScale.g300,
      },
      '& p':{
        marginLeft:'0.375rem !important'
      }
    },
    compactInput: {
      [theme.breakpoints.up('md')]:{
        width:'100%'
      }
    },
    dropZoneSection: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    button: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('lg')] : {
        marginBottom: theme.spacing(6)
      }
    },
    termsAndPolicy:{
      color: theme.palette.primary.main2
    },
    submitError: {
      color: theme.palette.error.main,
      textAlign: 'center',
      marginTop: theme.spacing(2),
      padding: `0 ${theme.spacing(3)}px`
    },
  })
);

const CUIL_CUIT_LABEL = 'CUIT o CUIL (opcional)';
const CUIL_CUIT_MASK = [/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/,];
const NAME_LABEL = 'Nombre';
const LASTNAME_LABEL = 'Apellido';
const EMAIL_LABEL = 'E-mail';
const SUBMIT_ERROR_MESSAGE = '¡ Lo sentimos ! No se pudo realizar el registro. Intenta nuevamente más tarde.';
const REGISTER_BTN_TXT = 'Registrarme';
const DNI_LABEL = 'DNI';
const DNI_MASK =  new Array(8).fill(/\d/);
const PHONE_LABEL = 'Teléfono';
const PHONE_MASK =  new Array(10).fill(/\d/);
const PHONE_HELPER = (
  <Box fontSize="11px" ml="0.625rem">
    Ingresá tu número sin el 0 y sin el 15.
  </Box>
)
const TERM_LABEL = 'Términos y condiciones';
const POLICY_LABEL = 'Políticas de privacidad';
const POLICY_ERROR_TEXT = 'Debe aceptar los Términos y condiciones y Políticas de privacidad para registrarse';


const Form = ({ onSubmit, valid, errorMessages, validateForm, initialFields, submitError, termsAndPolicy }) => {
  const classes = useStyle();

  const [formSubmited, setFormSubmited] = useState(false);
  const [fields, setFields] = useState(initialFields);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    const newFields = { ...fields };
    newFields.checked = event.target.checked;
    setFields(newFields);
    formSubmited && validateForm(newFields);
  };

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields };
    newFields[key] = value;
    setFields(newFields);
    formSubmited && validateForm(newFields);
  };

  const handleDropzoneUpload = (id, file, _) => {
    handleTextFieldChange(id, file);
  };

  const handleDropzoneDelete = (id, _) => {
    handleTextFieldChange(id, null);
  };


  function handleSubmit(e) {
    e.preventDefault();
    const vf = validateForm(fields);
    const isValid = vf && checked;
    onSubmit(fields, isValid);
    setFormSubmited(true);
  }

  return (
    <Box className={classes.registerForm}>
      <form onSubmit={handleSubmit}>
            <TextField
              error={errorMessages && !errorMessages.name.valid}
              helperText={errorMessages && errorMessages.name.error}
              className={clsx(classes.textInput,classes.compactInput)}
              data-test-id="input-register-name"
              label={NAME_LABEL}
              value={fields.name}
              onChange={(evt) => handleTextFieldChange('name' ,evt.target.value)}
              variant="outlined"
              tabIndex={0}
            />
              <TextField
                error={errorMessages && !errorMessages.lastName.valid}
                helperText={errorMessages && errorMessages.lastName.error}
                className={clsx(classes.textInput,classes.compactInput)}
                data-test-id="input-register-last-name"
                label={LASTNAME_LABEL}
                value={fields.lastName}
                onChange={(evt) => handleTextFieldChange('lastName' ,evt.target.value)}
                variant="outlined"
                tabIndex={1}
              />
        <MaskedTextField
          error={errorMessages && !errorMessages.dni.valid}
          helperText={errorMessages && errorMessages.dni.error}
          className={classes.textInput}
          data-test-id="input-register-dni"
          label={DNI_LABEL}
          value={fields.dni}
          onChange={(evt) => handleTextFieldChange('dni', evt.target.value)}
          mask={DNI_MASK}
          variant="outlined"
          tabIndex={3}
        />
        <MaskedTextField
          error={errorMessages && !errorMessages.phone.valid}
          helperText={errorMessages ? errorMessages?.phone?.error : PHONE_HELPER}
          className={classes.textInput}
          data-test-id="input-register-phone"
          label={PHONE_LABEL}
          placeholder="Ej.: 3511234567"
          value={fields.phone}
          onChange={(evt) => handleTextFieldChange('phone', evt.target.value)}
          mask={PHONE_MASK}
          variant="outlined"
          tabIndex={3}
        />
        <TextField
          error={errorMessages && !errorMessages.email.valid}
          helperText={errorMessages && errorMessages.email.error}
          className={classes.textInput}
          data-test-id="input-register-email"
          label={EMAIL_LABEL}
          value={fields.email}
          onChange={(evt) => handleTextFieldChange('email', evt.target.value)}
          variant="outlined"
          tabIndex={2}
          inputProps={{
            maxLength: '60'
          }}
        />
        <MaskedTextField
          error={errorMessages && !errorMessages.cuilCuit.valid}
          helperText={errorMessages && errorMessages.cuilCuit.error}
          className={classes.textInput}
          data-test-id="input-register-cuil-cuit"
          label={CUIL_CUIT_LABEL}
          value={fields.cuilCuit}
          onChange={(evt) =>
            handleTextFieldChange('cuilCuit', evt.target.value)
          }
          mask={CUIL_CUIT_MASK}
          variant="outlined"
          tabIndex={4}
        />
        <Box className={classes.dropZoneSection}>
          <DropZoneSection
            errorMessages={
              errorMessages && {
                sidePhoto: errorMessages.sidePhoto,
                insidePhoto: errorMessages.insidePhoto,
                frontPhoto: errorMessages.frontPhoto,
              }
            }
            onPhotoUpload={(id, file, hasError) =>
              handleDropzoneUpload(id, file, hasError)
            }
            onPhotoDelete={(id, hasError) => handleDropzoneDelete(id, hasError)}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Checkbox
              checked={checked}
              onChange={(event) => handleCheckboxChange(event)}
            />
          </Box>
          <Box m="0.75rem 0.5rem 1.5rem 0.5rem">
            <Text>
              Acepto <Link target="_blank" rel="noreferrer" href={termsAndPolicy.termsLink} download className={classes.termsAndPolicy}>{TERM_LABEL}</Link> y
              &nbsp;<Link target="_blank" rel="noreferrer" href={termsAndPolicy.policyLink} download className={classes.termsAndPolicy}>{POLICY_LABEL}</Link>.
            </Text>
          </Box>
        </Box>
        {formSubmited && !checked && (
          <FormHelperText error={true}>{POLICY_ERROR_TEXT}</FormHelperText>
        )}

        {submitError && (
          <Box className={classes.submitError}>{SUBMIT_ERROR_MESSAGE}</Box>
        )}
        <Button
          primary
          data-test-id="input-register-btn"
          className={classes.button}
          type="submit"
          fullWidth
          disabled={!checked}
        >
          {REGISTER_BTN_TXT}
        </Button>
      </form>
    </Box>
  )
};

Form.propTypes = {
  onSubmit : PropTypes.func,
  valid : PropTypes.bool,
  validations : PropTypes.object,
  errorMessages : PropTypes.object,
  validateForm : PropTypes.func,
  initialFields : PropTypes.object,
  submitError:  PropTypes.node,
};

Form.defaultProps = {
  onSubmit : () => {},
  valid : true,
  validations : {},
  errorMessages : {},
  validateForm : () => {},
  initialFields : {},
  submitError: null,
};

export default Form;
